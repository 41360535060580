// import Com1 from "../../../common/assets/icons/testIcons/Company1.png";
// import Com2 from "../../../common/assets/icons/testIcons/Company2.png";
// import Com3 from "../../../common/assets/icons/testIcons/Company3.png";
// import Com4 from "../../../common/assets/icons/testIcons/Company4.png";
// import Com5 from "../../../common/assets/icons/testIcons/Company5.png";

import Client1 from "../../../common/assets/icons/testIcons/client1.png";
import Client2 from "../../../common/assets/icons/testIcons/client2.png";
import Client3 from "../../../common/assets/icons/testIcons/client3.png";
import Client4 from "../../../common/assets/icons/testIcons/client4.png";
import Client5 from "../../../common/assets/icons/testIcons/client5.png";
import Client6 from "../../../common/assets/icons/testIcons/client6.png";
import Client7 from "../../../common/assets/icons/testIcons/client7.png";
import Client8 from "../../../common/assets/icons/testIcons/client8.png";
import Client9 from "../../../common/assets/icons/testIcons/client9.png";
import Client10 from "../../../common/assets/icons/testIcons/client10.png";
//import Client11 from "../../../common/assets/icons/testIcons/client11.png";
import Client12 from "../../../common/assets/icons/testIcons/client12.png";
import Client13 from "../../../common/assets/icons/testIcons/client13.png";
import Client14 from "../../../common/assets/icons/testIcons/client14.png";
import Client15 from "../../../common/assets/icons/testIcons/client15.png";
import Client16 from "../../../common/assets/icons/testIcons/client16.png";
import Client17 from "../../../common/assets/icons/testIcons/client17.png";
import Client18 from "../../../common/assets/icons/testIcons/client18.png";
import Client19 from "../../../common/assets/icons/testIcons/client19.png";


import "../styles/OurClientsBlock.css"
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";


interface IOurClientsBlock {
}

// const data = [
//   <ImgSliderCard content={<ImgSliderCardImg src={Com1} iClass="m_ocb_img"/>} active={false} acticeClass="" cClass="" key={0}/>,
//   <ImgSliderCard content={<ImgSliderCardImg src={Com2} iClass="m_ocb_img"/>} active={false} acticeClass="" cClass="" key={1}/>,
//   <ImgSliderCard content={<ImgSliderCardImg src={Com3} iClass="m_ocb_img"/>} active={false} acticeClass="" cClass="" key={2}/>,
//   <ImgSliderCard content={<ImgSliderCardImg src={Com4} iClass="m_ocb_img"/>} active={false} acticeClass="" cClass="" key={3}/>,
//   <ImgSliderCard content={<ImgSliderCardImg src={Com5} iClass="m_ocb_img"/>} active={false} acticeClass="" cClass="" key={4}/>,
// ] 
const clientsIcon = [Client1, Client2, Client3, Client4, Client5, Client6, Client7, Client8, Client9, Client10, Client12, Client13, Client14, Client15, Client16, Client17, Client18, Client19]

const scrollStep = 300

export const OurClientsBlock = ({}: IOurClientsBlock) => {
  const refContainer = useRef(null);

  const onNextClicked = () => {
    if(refContainer != null) {
      //@ts-ignore
      let newVal = refContainer.current.scrollLeft + scrollStep
      //@ts-ignore
      if(newVal < refContainer.current.scrollWidth) refContainer.current.scrollLeft =  newVal
      //@ts-ignore
      else refContainer.current.scrollLeft = refContainer.current.scrollWidth
    }
    //@ts-ignore
    //if(refContainer != null && refContainer.current.scrollLeft < refContainer.current.scrollWidth) refContainer.current.scrollLeft += 30
  }

  const onPrevClicked = () => {
    if(refContainer != null) {
      //@ts-ignore
      let newVal = refContainer.current.scrollLeft - scrollStep
      //@ts-ignore
      if(newVal > 0) refContainer.current.scrollLeft =  newVal
      //@ts-ignore
      else refContainer.current.scrollLeft = 1
    }
    //@ts-ignore
    //if(refContainer != null && refContainer.current.scrollLeft > 30) refContainer.current.scrollLeft -= 30
  }

  return (
    <div style={{width: "100%"}} >
      <p className="m_ocb_title">Наши клиенты</p>
      {/* <ImgSlider2 data={data} currIdx={0} classMainCon="m_ocb_slider_main" classDataCon="m_ocb_data_con"/> */}
      <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{height: "100%", margin: "auto 0 auto auto"}}>
            <Button className="data_slider_wrapper_prev" onClick={onPrevClicked}><ChevronLeft/></Button>
          </div>
          <div ref={refContainer} className="data_slider_wrapper">
            {clientsIcon.map((c, i) => (
              <div key={i} className="item">
                <img src={c} alt="" style={{width: "110px"}}/>
              </div>
            ))}
          </div>
          <div style={{height: "100%", margin: "auto auto auto 0"}}>
            <Button className="data_slider_wrapper_next" onClick={onNextClicked}><ChevronRight/></Button>
          </div>
      </div>
    </div>
  );
};

