import { useEffect } from "react";
import { namePathMain, namePathRealAutomation, pathMain, pathRealAutomation } from "../../common/config/paths";
import { TopPathWay } from "../../common/ui/TopPathWay";
import { Banners } from "./molecules/Banners";
import { Hog } from "./molecules/Hog";
import { Experience } from "./molecules/Experience";

import "./styles/index.css"
import { Button } from "react-bootstrap";
import { elemID_TabContact2usConNameForm } from "../../common/config/vars";
import { useHistory } from "react-router-dom";
//import { ProductList } from "./organoids/ProductList";
const topWay = [
  {name: namePathMain, path: pathMain},
  {name: namePathRealAutomation, path: pathRealAutomation},
]

const aboutVacancy = (
  //@ts-ignore
  <div style={{textAlign: "justify", fontSize: "16px", lineHeight: "19px"}}>
    <strong>АБС - это IT-компания с командой профессионалов.</strong><br/>
    Более 20 лет  на рынке. Мы развиваем сотрудничество с Microsoft, среди наших клиентов значимые компании Красноярского края, крупные холдинги.<br/><br/>

    В лице компании вы получите <strong>надежного работодателя</strong>, который объединил в команду профессионалов.<br/><br/>

    Молодым и амбициозным мы предлагаем обучение у лучших наставников-экспертов, надежную базу для старта, карьерный рост и профессиональное развитие.<br/>
    Растите, повышайте профессионализм, реализуйте амбиции и заводите друзей в кругу активных и целеустремленных людей.
  </div>)

  const AboutRealAutomation = `
    Бывают ситуации, когда в компании есть понимание, что автоматизироваться нужно, но нет четкого понимания, 
    что именно автоматизировать, или нет возможности сформулировать, что конкретно нужно сделать – например, 
    нет четких бизнес-процессов и поэтому непонятно, с чего начать, а, возможно,
    нет своих ресурсов для выявления задач, требующих автоматизации.`

  const TextBeforeOrderBtn = `Вы задумывались о необходимости автоматизации, но не знаете с чего начать.`

export const RealAutomation = () => {
  const history = useHistory()

  useEffect(() => {
    document.title = namePathRealAutomation
  }, [])

  const onBtnOrderClicked = () => {
    history.push(pathMain + "#" + elemID_TabContact2usConNameForm)
  }

  return (
    <div className="page_main_con" >
      <div className="ra_idx_main_sub_con">
        <TopPathWay list={topWay} mainClass="ra_idx_tpw_main_con"/>
        <p className="page_title gradient1 ra_idx_title">{namePathRealAutomation}</p>
        <p className="ra_idx_sub_title1">УЗНАЙТЕ, КАК СДЕЛАТЬ ВАШ БИЗНЕС ЭФФЕКТИВНЕЕ</p>
        <p className="ra_idx_text1">{AboutRealAutomation}</p>
        

        <Banners/>
        <div className="ra_idx_order_con">
        <p className="ra_idx_text1 ra_idx_text2">{TextBeforeOrderBtn}</p>
        <Button variant="default" className="ra_idx_btn_order" onClick={onBtnOrderClicked} >Оставить заявку</Button>
        </div>
        <Hog/>
        <Experience/>
        <p className="ra_idx_footer_notice">Повышайте эффективность бизнеса с нами! Станьте лидером своей отрасли вместе с решениями 1С!</p>
      </div>
    </div>
  );
};




