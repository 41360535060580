import IconAuto1 from "../../../common/assets/icons/IconAuto1.png";
import IconAuto2 from "../../../common/assets/icons/IconAuto2.png";
import { AutoListItem } from "../atoms/AutoListItem";
import { AutomationInputContainer } from "../atoms/AutomationInputContainer";
import { ImgSlider2 } from "../../../common/ui/ImgSlider2";
import { ImgSliderCard } from "../../../common/ui/ImgSlider2/molecules/ImgSliderCard";

import "../styles/AutomationGroup.css"

interface IAutomationGroup {
  hash: string
}

const auto1list = [
  {id: "1", name: "Обследование предприятия и разработку технического задания;"},
  {id: "2", name: "Продажу готовой программы, если она имеется на рынке;"},
  {id: "3", name: "Доработку программы под предметную область, если исходная программа не обладает всеми необходимыми фукнциональными возможностями;"},
  {id: "4", name: "Внедрение бизнес-процессов посредством программы на базе 1С:Предприятия;"},
  {id: "5", name: "Аутсорсинг автоматизированных бизнес-процессов."},
]
const auto2list = [
  {id: "1", name: "Бухгалтерский учет;"},
  {id: "2", name: "Налоговый учет;"},
  {id: "3", name: "Складской учет;"},
  {id: "4", name: "Кадровый учет;"},
  {id: "5", name: "Расчет заработной платы."}
]

const data = [
  <ImgSliderCard content={<AutoListItem title="IT-Компания “АБС” предлагает следующие услуги:" icon={IconAuto1} list={auto1list} />} active={false} acticeClass="" cClass="m_ag_slider_card" key={0}/>,
  <ImgSliderCard content={ <AutoListItem title="Успешно автоматизировали:" icon={IconAuto2} list={auto2list} />} active={false} acticeClass="" cClass="m_ag_slider_card" key={1}/>,
] 

export const AutomationGroup = ({hash}: IAutomationGroup) => {

  return (
    <div className="row col-12 m_ag_main_con ">
      <div className="m_ag_main_sub_con">
        <div className="m_ag_input_con">
          <AutomationInputContainer hash={hash} />
          <div className="m_ag_rectangle1" ></div>
          <div className="m_ag_rectangle2" ></div>
        </div>
        <div  className="m_ag_auto_list_con" >
          <div className="m_ag_auto_list_con_desktop">
            <span className="m_ag_auto_list_title">Задумались об автоматизации? Поддерживаем!</span>
            <span className="m_ag_auto_list_sub_title">С 2002 года наше предприятие является постоянным партнером фирмы 1С в рамках договора франчайзинга.</span>
            <AutoListItem title="IT-Компания “АБС” предлагает следующие услуги:" icon={IconAuto1} list={auto1list} />
            <AutoListItem title="Успешно автоматизировали:" icon={IconAuto2} list={auto2list} />
          </div>
          <div className="m_ag_auto_list_con_tel" >
            <ImgSlider2 data={data} currIdx={0} classMainCon="m_ag_slider_main_con" hidePagination={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
