import { contactUsEmail, contactUsTel } from "../../config/vars";
import "./styles/index.css";

const comInfo ="© IT компания АБС 2000-" + (new Date()).getFullYear() + " г. Все права защищены."

export const Footer = () => {

  return (
     <footer className="footer_tag">
       <div className="footer_contact_us_con">
         <a href={"tel:" + contactUsTel} >{contactUsTel}</a>{contactUsEmail}
        </div>
       <span className="footer_com_info">{comInfo}</span>
     </footer>
  );
};
