// import IconAuto1 from "../../../common/assets/icons/IconAuto1.png";
// import IconClient1 from "../../../common/assets/icons/testIcons/client1.png";
// import IconClient2 from "../../../common/assets/icons/testIcons/client2.png";
// import IconClient3 from "../../../common/assets/icons/testIcons/client3.png";
// import IconClient4 from "../../../common/assets/icons/testIcons/client4.png";
// import IconClient5 from "../../../common/assets/icons/testIcons/client5.png";
// import IconClient6 from "../../../common/assets/icons/testIcons/client6.png";
// import IconClient7 from "../../../common/assets/icons/testIcons/client7.png";
// import IconClient8 from "../../../common/assets/icons/testIcons/client8.png";
// import IconClient9 from "../../../common/assets/icons/testIcons/client9.png";
// import IconClient10 from "../../../common/assets/icons/testIcons/client10.png";
// import IconClient11 from "../../../common/assets/icons/testIcons/client11.png";
// import IconClient12 from "../../../common/assets/icons/testIcons/client12.png";
// import IconClient13 from "../../../common/assets/icons/testIcons/client13.png";
// import IconClient14 from "../../../common/assets/icons/testIcons/client14.png";
// import IconClient16 from "../../../common/assets/icons/testIcons/client16.png";
// import IconClient17 from "../../../common/assets/icons/testIcons/client17.png";
// import IconClient20 from "../../../common/assets/icons/testIcons/client20.png";
// import IconClient21 from "../../../common/assets/icons/testIcons/client21.png";
// import { AutoListItem } from "../atoms/AutoListItem";
// import { AutomationInputContainer } from "../atoms/AutomationInputContainer";
// import { FeedbackSlider } from "./FeedbackSlider";

import { OurClientsBlock } from "./OurClientsBlock";

import { FeedbackGroupItem } from './FeedbackGroupItem';
import "../styles/FeedbackGroup.css"
import {getData} from "./FeedbackDataLoad";

import '../styles/FeedbackSlider.css';
import { Carousel } from "react-bootstrap";
import { ChevronLeft, ChevronRight, Slash } from "react-bootstrap-icons";
import { useState} from "react";
import { IMAGES_URL } from "../../../common/config/vars";
 
interface IAutomationGroup {
  hash: string
}



// export var SliderData = [       
  // {
  //   icon: IconClient8,
  //   clientName: "ОАО Енисейское речное пароходство",
  //   feedback: "АО «Енисейское речное пароходство» является основным перевозчиком грузов по водным путям бассейна реки Енисей с грузооборотом свыше 4 млн тонн в год.." +
  //   "С компанией АБС сотрудничаем с 2006 г. В целом процессы внедрения проходят без осложнений и в установленные сроки. Вопросы, возникающие у пользователей при работе с программой, решаются дистанционно и оперативно. Рекомендуем компанию АБС для решения автоматизации деятельности.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },];
  // {
  //   icon: IconClient14,
  //   clientName: "Медведь Холдинг",
  //   feedback: "На сегодняшний день Группа компаний «Медведь Холдинг» является официальным дилером и владельцем крупнейших в Красноярском крае дилерских центров. " +
  //     "\"Хотелось бы поблагодарить за компетенцию и оперативную работу сотрудников компании \"АБС\" Ваша компания как представитель фирмы 1С оказывает нам ощутимую поддержку - технические проблемы обнаруживаются и решаются достаточно оперативно и квалифицированно. сотрудники компании всегда корректны и вежливы в общении. А централизованный контроль за выполнением заявок позволяет оперативно обнаружить и решать возникающие задачи\"",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient20,
  //   clientName: "Субару ЦЕНТР КРАСНОЯРСК",
  //   feedback: "Субару является автомобильным подразделением компании Fuji Heavy Industries Ltd. (FHI), производителем обширного и многогранного транспортного оборудования. " +
  //   "Благодарим IT-компанию «АБС» за плодотворное сотрудничество в области доработки программного продукта на базе 1С: Альфа-Авто: Автосалон + Автосервис + Автозапчасти, ред. 5. Не смотря на возникшие сложности при проведении работ, результат был получен в срок и с должным качеством. С учетом наработанного опыта сотрудничества, рекомендуем компанию «АБС» и ее сотрудников как ответственного бизнес-партнера.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient13,
  //   clientName: "ОАО «Красноярскэлектросетьстрой»",
  //   feedback: "ОАО «Красноярскэлектросетьстрой» является специализированной организацией по строительству линий электропередач напряжением 10-1150 кВ, строительной части трансформаторных подстанций напряжением 35-1150 кВ и распределительных устройств. Выражаем благодарность компании АБС за успешное сотрудничество по проектам автоматизации рабочих мест производственно-технического отдела." +
  //   "Хотелось бы отдельно отметить способность сотрудников компании \"АБС\" эффективность решать сложные задачи автоматизации, требующие проектирования и разработки.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient10,
  //   clientName: "КрасГМУ",
  //   feedback: "Красноярский государственный медицинский университет имени профессора В. Ф. Войно-Ясенецкого — медицинский ВУЗ в Красноярском крае, один из крупнейших университетов медицинского профиля Сибири и Дальнего Востока России. " + 
  //   "Мы пользуемся услугами IT - Компании АБС с 2009 г. Первоначально было проведено внедрение с переходом на новую платформу, уже имеющейся программы. " +
  //   "Так же подрядчик поддерживает нашу систему в актуальном состоянии. Бережно поддерживают индивидуальные особенности нашего учета. Мы удовлетворены уровнем и качеством обслуживания, и планируем работать с АБС в дальнейшем.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient1,
  //   clientName: "АКБ «ЕНИСЕЙ»",
  //   feedback: "АКБ «ЕНИСЕЙ» создан на базе Промстройбанка-ведущей финансовой организации СФО, оказывавшей поддержку промышленным предприятиям Красноярского края с 1990 году. " +
  //   "Мы являемся клиентом АБС длительное время. За время нашей совместной работы сотрудники компании АБС проявили себя как грамотные специалисты, оперативно решающие все возникающие задачи. Особо хочется отметить профессионализм, высокую работоспособность, самоотдачу в каждодневной кропотливой труде сотрудников отдела сопровождения.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient7,
  //   clientName: "ЗАО АИКБ Енисейский объединенный банк",
  //   feedback: "Енисейский объединенный банк отметил в январе 2012 года 19 лет со дня образования. Более 25% акций Енисейского объединенного банка принадлежит Красноярскому краю. " +
  //   "ЗАО АИКБ «Енисейский Объединенный банк» выражает благодарность ИТ-компании «АБС» за автоматизацию системы ведения учета основных средств на базе «1С:Бухгалтерия 8».",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient9,
  //   clientName: "КАЙРОС",
  //   feedback: "Транспортная компания «КАЙРОС» осуществляет деятельность автомобильного грузового специализированного транспорта на карьерных и лесозаготовительных работах, а также организацию перевозок грузов. " +
  //   "По итогам качественной и интересной работы с АБС нам удалось реализовать необходимые нам возможности, а также найти новые точки опоры как для развития программного продукта 1С, так и развития бизнеса. Планируем двигаться дальше в этом направлении.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient2,
  //   clientName: "ArtStyle",
  //   feedback: "Дизайн-группа ArtStyle основана в 1992 году. За это время мы разработали и реализовали комплексные проекты более чем для 500 событий, создали более 200 брендов и получили более 50 наград национальных и международных конкурсов. " +
  //   "Хотелось бы поблагодарить за совместную работу и поддержку компанию \"АБС\" " +
  //   "В процессе работ ООО «АБС» детально разобралось с существующей системой управления заказами, разработало новую логику бизнес-процессов с учетом возможностей программы «1С:Оперативная печать 8». Были созданы и внедрены новая производственная документация и уникальные отчеты. " +
  //   "ООО «АБС» расцениваем как профессиональные, ответственные и клиент ориентированные. Рекомендуем ООО «АБС» для решения подобных задач.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient4,
  //   clientName: "Вербицкие",
  //   feedback: "Наша история началась более 20 лет назад: с обычной печки на даче, где мы коптили куриные деликатесы для семьи и близких. С тех пор наш бизнес рос и развивался. Мы дали нашему производству свою фамилию, чтобы показать, что качество и вкус – традиция нашей семьи. " +
  //   "По итогам сложного и длинного совместного пути с АБС нам удалось найти новые точки опоры как для развития программного продукта 1С:ERP, так и развития бизнеса. Планируем двигаться дальше в этом направлении.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient16,
  //   clientName: "АО НПП «Радиосвязь»",
  //   feedback: "АО «НПП «Радиосвязь» осуществляет разработку и  изготовление наземных станций спутниковой и тропосферной связи, системы и аппаратуру фазовой навигации. " +
  //   "Для выбора подрядчика был проведен конкурс тендерных закупок, в котором победила IT-компания «АБС», пройдя по всем нашим требованиям. Компания АБС, проявила себя как добросовестный подрядчик с квалифицированными специалистами. В данный момент наше предприятие продолжает сотрудничать с ООО «АБС» в рамках поставки других программных продуктов и договора информационно-технологического сопровождения.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient17,
  //   clientName: "ООО \"Сибуголь\"",
  //   feedback: "ООО \"Сибуголь\" занимается добычей бурого угля на участках \"Новый\" и \"Восточный\" Большесырского месторождения расположенного на территории Балахтинского района Красноярского края. " +
  //   "ООО «Сибуголь» положительно оценивает помощь АБС в выборе и внедрении программного обеспечения и планирует двигаться дальше в его развитии, автоматизируя следующие блоки  подразделений связанных с автотранспортом.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },
  // {
  //   icon: IconClient21,
  //   clientName: "ТОНЕР",
  //   feedback: "Компания ТОНЕР была создана 19 декабря 1997 года. Одно из основных направлений деятельности компании - оптовые и розничные продажи современной и качественной техники для офисного и домашнего применения, а также комплектующих и расходных материалов. " +
  //   "С ИТ-компанией «АБС» сотрудничество началось в 2012 году. Переход, как и доработка функционала, были оценены и в кратчайшие сроки реализованы сотрудниками компании «АБС». В данный момент наше предприятие продолжает сотрудничать с «АБС» в рамках договора сопровождения.",
  //   fio: "Батюцкий В. А.", 
  //   position: "Начальник управления автоматизации."
  // },

  
// ];

const data: JSX.Element[] = [] 

// SliderData.forEach((e, i) => {
//    //data.push(<ImgSliderCard content={<FeedbackGroupItem key={i} icon={e.icon} feedback={e.feedback} fio={e.fio} position={e.position} />} active={false} acticeClass="" cClass="m_feedg_cards_slider" key={i}/>)
//    console.log(e)
// })


export const FeedbackGroup = ({hash}: IAutomationGroup) => {
  const [currKey, setCurrKey] = useState(1)
  const [SliderData, changeSliderData] = useState([])
 
 

  if(SliderData.length===0){
   //@ts-ignore
    getData().then(data=>{changeSliderData(data)})
  }
  

  function onSlide(key: number, direction: "right" | "left") {
    setCurrKey(key+1)
  }
  return (
    <div className="row col-12 m_feedg_main_con ">
      <OurClientsBlock/>
      <p className="m_feedg_clients_trust_us_con">Наши заказчики доверяют нам</p>
      {/* <div className="m_feedg_main_sub_con">
        <ImgSlider2 data={data} classMainCon="m_feedg_slider_main_con" classSection="m_feedg_slider_sec"  hidePagination={false}/>
      </div> */}
      <div style={{width: "100%"}} >
      <div style={{margin: "auto", maxWidth: "800px"}} >
        <Carousel pause={"hover"} indicators={false} onSlide={onSlide} aria-orientation="horizontal" className="feedback_carousel" prevIcon={<ChevronLeft className="slide_btn_icon"/>} nextIcon={<ChevronRight className="slide_btn_icon"/>}  >
         
          {//@ts-ignore
          SliderData.map((e,i) => (
            
            <Carousel.Item  key={i}  >
              {/* {e.feedback} */}
           
              <FeedbackGroupItem key={i} icon={//@ts-ignore 
              IMAGES_URL + e.LogoPath} feedback={e.Description} fio={e.fio} position={e.position} comName={e.Name} />
            </Carousel.Item>
          ))}
        </Carousel>
        
        <div style={{margin: "auto"}}>{currKey}<Slash style={{fontSize: "30px", margin: "auto", padding: "0"}}/>{
        //@ts-ignore
        SliderData.length}</div>
      </div>
      </div>

    </div>
  );
}
