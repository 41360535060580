import { useState } from "react";
import { Button } from "react-bootstrap";
import "../styles/FeedbackInfo.css"

interface IFeedbackInfo {
  icon: string,
  feedback: string,
  fio: string,
  position: string,
  comName: string
}

export const FeedbackInfo = ({icon, feedback,comName}: IFeedbackInfo) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div className="m_fginf_main">
        <div className="m_fginf_title_con">
          <p className="m_fginf_title">{comName}</p>
          <img src={icon} alt="" className="m_fginf_icon" />
        </div>
        <span className={"m_fginf_feedback " + (showMore ? "m_fginf_feedback_full" : "")}>{feedback}</span>
        <Button variant="default" className="m_fginf_btn_show_more" onClick={() => setShowMore(!showMore)}>{showMore ? "Скрыть" : "Показать ещё"}</Button>
        {/* <FeedbackAuthorContainer fio={fio} position={position} /> */}
    </div>
  );
}
