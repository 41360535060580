import {Switch, Route, Redirect} from 'react-router-dom';
import {Header} from '../src/common/ui/Header';
import {Footer} from '../src/common/ui/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { pathAbout, pathClients, pathMain, pathProducts, pathRealAutomation, pathVacancy,pathConvoy } from './common/config/paths';
import { SectionAutomation } from './pages/MainPage/organoids/SectionAutomation';
import './common/assets/fonts/fonts.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; 
import { Products } from './pages/Products';
import { Vacancy } from './pages/Vacancy';
import { Convoy } from './pages/Convoy';
import { AboutCompany } from './pages/AboutCompany';
import { RealAutomation } from './pages/RealAutomation';
import { YMInitializer } from 'react-yandex-metrika';

function App() {
  const EmptyContent = <div style={{minHeight: "calc(100vh - 167px)", paddingTop: "30px"}}>Empty content</div>

  return (
    <div className="App">
      <YMInitializer accounts={[89209310]} />
      <Header />
      <Switch>
        <Route exact path={pathClients} children  = { (props) => EmptyContent}></Route>
        <Route exact path={pathAbout} children  = { (props) => <AboutCompany/>}></Route>
        <Route exact path={pathProducts} children  = { (props) => <Products/>}></Route>
        <Route exact path={pathConvoy} children  = { (props) => <Convoy/>}></Route>
        <Route exact path={pathVacancy} children  = { (props) => <Vacancy/>}></Route>
        <Route exact path={pathRealAutomation} children  = { (props) => <RealAutomation/>}></Route>
        <Route path={pathMain} children ={ props => ( <SectionAutomation hash={props.location.hash}/>)}></Route> 
        <Route><Redirect to={pathMain}/></Route>
      </Switch>
      <Footer/>
      <ToastContainer />
    </div>
  );
}

export default App;
