import React, { useEffect } from "react";
import { namePathMain, namePathVacancy, pathMain, pathVacancy } from "../../common/config/paths";
import { TopPathWay } from "../../common/ui/TopPathWay";
import { JobConditions } from "./molecules/JobConditions";
import { JoinOurTeam } from "./molecules/JoinOurTeam";
//import { ProductList } from "./organoids/ProductList";
import "./styles/ForIndex.css";

const topWay = [
  {name: namePathMain, path: pathMain},
  {name: namePathVacancy, path: pathVacancy},
]

const aboutVacancy = (
  //@ts-ignore
  <div style={{ textAlign: "justify", fontSize: "16px", lineHeight: "19px"}}>
    <strong>АБС - это IT-компания с командой профессионалов.</strong><br/>
    Более 20 лет  на рынке. Мы развиваем сотрудничество с Microsoft, среди наших клиентов значимые компании Красноярского края, крупные холдинги.<br/><br/>

    В лице компании вы получите <strong>надежного работодателя</strong>, который объединил в команду профессионалов.<br/><br/>

    Молодым и амбициозным мы предлагаем обучение у лучших наставников-экспертов, надежную базу для старта, карьерный рост и профессиональное развитие.<br/>
    Растите, повышайте профессионализм, реализуйте амбиции и заводите друзей в кругу активных и целеустремленных людей.
  </div>)

export const Vacancy = () => {

  useEffect(() => {
    document.title = namePathVacancy
  }, [])

  return (
    <div className="page_main_con" >
      <div className="v_idx_main_sub_con">
        <TopPathWay list={topWay} />
        <p className="page_title gradient1">{namePathVacancy}</p>
        <div className="idx_ac_about_con">
          {aboutVacancy}
        </div>
        <JobConditions/>
        <JoinOurTeam/>
      </div>
    </div>
  );
};




