import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import IconAutomation from "../../../common/assets/icons/IconAutomation.png"
import { querySendMail } from '../../../common/sendMail';
import { toast } from 'react-toastify';
import { validatePhoneRU } from '../../../common/functions';
import { orderDestinationEmail, orderSenderName, orderDestinationName } from "../../../common/config/vars";
import "../styles/OrderCallFormAndIcon.css"

export const OrderCallFormAndIcon = () => {

  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [failMsg, setFailmsg] = useState("")
  const [okMsg, setOkMsg] = useState("")
  const [btnOff, setBtnOff] = useState(true)

  useEffect(() => {
    if(!loading) {
      if(failMsg.length > 0) {
        toast.error(failMsg)
      } else if (okMsg.length > 0) {
        toast.success(okMsg)
      }
      setFailmsg("")
      setOkMsg("")
    }
     // eslint-disable-next-line
  }, [loading])

  const onPhoneChanged = (p: string) => {
    let valid = validatePhoneRU(p)
    setPhone(valid.phone)
    setBtnOff(!valid.ok)
  }

  function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault();
    console.log("sub")
    querySendMail({destMail: orderDestinationEmail, destName: orderDestinationName, senderName: orderSenderName, setLoading: setLoading, setFailMsg: setFailmsg, setOkMsg: setOkMsg, title: "Заказ звонка", text: "На сайте АБС-Визитка заказали звонок на номер "+ phone})
  }

  return (
    <div className="row col-12 m_ocfai_main_con ">
      <div className="m_ocfai_call_form_con">
        {/*<div style={{display: "flex", flexDirection: "column", maxWidth: "480px"}}>*/}
          <span className="m_ocfai_automate_business_span gradient1">Автоматизируем ваш бизнес.</span>
          <div style={{width: "100%", height: "100%"}}>
            <span className="m_ocfai_notice_span">Мы предлагаем современные программные решения для эффективного управления бизнесом.</span>
          </div>
          <form onSubmit={handleFormSubmit} style={{width: "100%"}}>
            <div className="m_ocfai_phone_con">
              <input required pattern="\+7\(\d{3}\)\d{3}-\d{2}-\d{2}" value={phone} onChange={e => onPhoneChanged(e.target.value)} className="m_ocfai_phone_form" title="Номер телефона" placeholder="  +7(XXX)XXX-XX-XX" type="text"/>
              <Button type="submit" disabled={loading || btnOff} className="m_ocfai_order_call_submit_btn" variant="default">
                {loading ? <Spinner animation="border" size="sm" /> : " Заказать звонок"}
              </Button>
            </div>
          </form>
        </div>
      {/*</div>*/}
      <div className="m_ocfai_icon_con">
        <img src={IconAutomation} className="m_ocfai_icon_con" alt=''></img>
      </div>
    </div>
  );
}
