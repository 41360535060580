import React, { useEffect } from "react";
import { namePathAbout, namePathMain, pathAbout, pathMain } from "../../common/config/paths";
import { TopPathWay } from "../../common/ui/TopPathWay";
import { InfoBlock } from "./molecules/InfoBlock";
import { PartnersBlock } from "./molecules/PartnersBlock";

import IconPartner1 from "../../common/assets/icons/testIcons/Partner1.png";

import "./styles/ForIndex.css";
import { ImgSlider } from "../../common/ui/ImgSlider";

import IconProduct1 from '../../common/assets/icons/testIcons/product1.png';
import IconProduct2 from '../../common/assets/icons/testIcons/product2.png';

const SliderData = [
  { src: IconProduct1, desc: "product1" },
  { src: IconProduct2, desc: "product2"  },
]

const topWay = [
  {name: namePathMain, path: pathMain},
  {name: namePathAbout, path: pathAbout},
]

const partners = [
  {icon: IconPartner1, desc: <span>
    «1С-Рарус» — совместное предприятие «1С» и «Рарус». За 27 лет на рынке Офисы группы компаний работают в 5 странах мира. Является крупнейшим разработчиком многих программ на базе 1С<br/>
  "Система менеджмента «1С-Рарус» соответствует международному стандарту качества ISO 9001:2015.</span>}
]

const aboutCompany = (
  //@ts-ignore
  <div style={{ textAlign: "justify"}}>
    <strong>Компания «Автоматизированные бизнес-системы» (IT-Компания «АБС»)</strong> создана в 2000 году.
    С 2002 года наше предприятие является постоянным партнером фирмы 1С в рамках договора франчайзинга.<br/>
    Мы развиваем сотрудничество с корпорацией Microsoft.<br/><br/>

    <strong>«АБС»</strong> предлагает современные программные решения для эффективного управления бизнесом, осуществляет комплексную 
    автоматизацию всех аспектов деятельности предприятия клиента. Нашими клиентами являются компании, осуществляющие непрерывное и дискретное
    производство, добычу полезных ископаемых, строительство, энергетические компании, автосалоны, транспортные компании, операторы связи и
    другие.
  </div>)

export const AboutCompany = () => {

  useEffect(() => {
    document.title = namePathAbout
    console.log(namePathAbout)
  }, [])




  
  return (
    <>
      <div className="page_main_con" >
        <div className="idx_ac_main_con">
          <TopPathWay list={topWay} />
          <p className="page_title gradient1">{namePathAbout}</p>
          <div className="idx_ac_about_con">
            {aboutCompany}
          </div>
          <p className="page_title gradient1">Наши партнеры</p>
          <PartnersBlock list={partners}/>
          <ImgSlider data={SliderData}/>
          <InfoBlock/>
        </div>
      </div>
    </>
  );
};

/*
.o_pl_main_con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
*/




